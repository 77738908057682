@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,400;0,600;0,700;0,900;1,400;1,600;1,700;1,900&display=swap');

html {
  scroll-behavior: smooth;
}

body {
  font-family: 'Source Sans Pro', -apple-system, BlinkMacSystemFont, 'Segoe UI', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #0D3050;
}

a {
  color: #122f46;
}

p {
  line-height: normal;
}

li {
  line-height: normal;
}

h1 {
    font-size: 36px;
    line-height: 40px;
    color: #175E9E;
    font-weight: 900;
}

h2 {
    font-size: 30px;
    line-height: 40px;
    color: #175E9E;
    font-weight: 900;
}

h3 {
    font-size: 24px;
    line-height: 40px;
    color: #175E9E;
    font-weight: 900;
}

h4 {
    font-size: 18px;
    line-height: 20px;
    color: #175E9E;
    font-weight: 900;
}

h5 {
    font-size: 14px;
    line-height: 20px;
    color: #175E9E;
    font-weight: 900;
}

h6 {
    font-size: 12px;
    line-height: 20px;
    color: #175E9E;
    font-weight: 900;
}

img.img-auto {
  width: 100%;
  height: auto;
}

.title {
  font-size: 36px;
}

.logo {
  width: 190px;
}

.header {
  background-color: #faf9f4;
  min-height: 720px;
  position: relative;
}

.header-bg {
  z-index: 0;
}

.header-bg::before {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50%;
  width: 100%;
  height: 100%;
  overflow: hidden;
  content: ' ';
  display: block;
  position: absolute;
  background-image: url(./img/bg-left.png);
  left: 0;
  top: 0;
}

.header-bg::after {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50%;
  width: 100%;
  height: 100%;
  overflow: hidden;
  content: ' ';
  display: block;
  position: absolute;
  background-image: url(./img/bg-right.png);
  left: 0;
  top: 0;
}

.header-menu {
  z-index: 2;
  padding: 40px;
  margin-bottom: 120px;
  position: relative;
}

.header-menu nav.main-menu {
  text-align: right;
  padding: 12px 0;
}

.header-menu ul {
  margin: 0;
  padding: 0;
}

.header-menu ul li {
  display: inline-block;
}

.header-menu ul li a {
  padding: 13px;
  font-size: 14px;
  font-weight: 900;
  text-decoration: none;
  position: relative;
  cursor: pointer;
}

.header-menu ul li a:hover::after {
  content: ' ';
  display: block;
  height: 2px;
  background-color: black;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 96%;
  right: 0;
  text-align: center;
  margin: auto;
}

.header-menu ul li.last a {
  /* padding-right: 0; */
}

.header-content h1 {
  font-size: 55px;
}

.header-content .subtitle {
  font-size: 30px;
  color: #175E9E;
  font-weight: bold;
  line-height: 32px;
}

.header-content .eco {
  display: flex;
  align-items: center;
  color: #7DAC2F;
  font-weight: bold;
  font-size: 25px;
}

.header-content .eco img {
  margin-right: 12px;
}

.header-content .btn {
  margin: 34px 0;
}

.btn {
  display: inline-block;
  padding: 17px 25px;
  border-radius: 35px;
  text-decoration: none;
  border: 0;
  box-shadow: none;
}

.btn.btn-primary {
  background-color: #0D3050;
  color: #fff;
  font-weight: 900;
  font-size: 22px;
}

.properties-wrapper {
  background-color: #faf9f3;
  padding: 0 0 33px 0;
}

.properties {
  position: relative;
  font-size: 18px;
  font-weight: 900;
  background-color: #fff;
  border-radius: 5px;
  margin-top: -115px;
  padding: 64px 38px;
}

.property {
  display: flex;
  align-items: center;
  color: #0D3050;
  font-weight: bold;
  font-size: 18px;
}

.property img {
  margin-right: 12px;
}

.content-section {
  padding: 66px 0;
}

.content-dark {
  background-color: #EEE;
}

.content-dark-2 {
  background-color: #7DA932;
}

.content-information {
  background-color: #0D3050;
  color: #fff;
  text-align: center;
  font-weight: 900;
  font-size: 20px;
  padding: 40px 0;
}

.content-brand {
  background-color: #7DA932;
  color: #fff;
  font-size: 20px;
  font-weight: 900;
  padding: 12px 0;
}

.content-highlight {
  background-color: #7DA932;
  padding: 14px 0;
  color: #fff;
}

.content-highlight a {
  color: #fff;
  font-weight: 900;
  display: inline-block;
  display: flex;
  align-items: center;
  font-size: 20px;
}

.content-highlight a img {
  margin-right: 14px;
}

.content-center h3,
.content-center h4,
.content-center .title {
  text-align: center;
}

.legal {
  color: #465E74;
  font-size: 15px;
  font-weight: lighter;
  display: block;
  text-align: center;
  margin-top: 45px;
}

.center {
  text-align: center;
  margin: 0 auto;
  display: block;
}

.usage-download {
  color: #7DA932;
  text-decoration: underline;
  font-size: 20px;
  font-weight: bold;
  display: flex;
  align-items: center;
  margin: auto;
}

.distributor {
  font-size: 15px;
}

.distributor b {
  font-size: 20px;
  display: block;
  margin-bottom: 6px;
}

.distributor-right {
  text-align: right;
}

.distributor-right > b {
  margin-bottom: 12px;
}

.download-usage-wrapper {
  text-align: center;
  display: flex;
  margin-top: 60px;
}

.download-text-wrapper {
  display: flex;
  justify-content: flex-end;
}

ul.colored-list {
  list-style: none;
  padding-left: 22px;
}

ul.colored-list > li::before {
  content: "\2022";
  color: #7eac2f;
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
  font-size: 16px;
  font-size: 22px;
}

.healing {
  text-align: right;
}

.app-image {
  display: block;
  max-width: 350px;
  margin: auto;
}

.mobile-menu {
  display: none;
}

.hamburger {
  display: none;
  position: relative;
  width: 25px;
  margin: 0 0 0 auto;
  cursor: pointer;
}

.hamburger .hamburger-elem {
  background: #0D3050;
  height: 2px;
  border-radius: 3px;
  margin: 4px;
}

nav.main-menu.mobile-menu {
  position: absolute;
  top: 90px;
  right: 0;
  padding: 0 40px;
  width: 100%;
}

.mobile-menu ul {
  background-color: #fff;
  width: 100%;
}

.mobile-menu ul li {
  display: block;
}

.header-menu .mobile-menu ul li a {
  padding: 12px 18px;
  display: block;
}

@media screen and (max-width: 1200px) {
  .properties {
    margin-top: -85px;
  }
}

@media screen and (max-width: 991px) {
  .properties {
    margin-top: -100px;
    padding: 38px;
  }

  .properties .property {
    padding: 12px 0;
  }

  .property-primary {
    padding: 18px;
    justify-content: center;
  }

  .header-menu {
    margin-bottom: 0;
  }

  .property.property-secondary {
    margin: 25px 0;
  }

  .header-bg::before {
    width: 120%;
  }

  .desktop-menu {
    display: none;
  }

  .hamburger,
  .app.open-nav .mobile-menu {
    display: block;
  }
}

@media screen and (max-width: 767px) {
  .properties {
    margin-top: 0;
  }

  .app-image {
    max-width: 220px;
  }

  .header-bg::after {
    top: -220px;
  }

  a.download-text {
    text-align: center;
    flex-direction: column;
    margin-bottom: 30px;
  }

  .content-highlight a img {
    display: block;
    width: 100%;
    margin-bottom: 20px;
  }

  .download-text-wrapper {
    justify-content: center;
  }

  .mobile-none {
    display: none !important;
  }

  .content-brand {
    text-align: center;
    padding-top: 30px;
  }

  .healing {
    text-align: center;
  }

  .header-content {
    text-align: center;
  }

  .header-content .eco {
    justify-content: center;
  }

  .distributor {
    font-size: 15px;
    text-align: center;
    margin: 24px 0 12px 0;
  }

  .header-bg::before {
    width: 160%;
  }

  nav.main-menu {
    display: none;
  }
}
